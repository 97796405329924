@import "../../styles/variables.scss";

.listing-faq__items {
    border-top: 1px solid #e3dede;
    margin-top: 16px;

    @media (min-width: 768px) {
        margin-top: 24px;
    }

    .listing-faq__item {
        border-bottom: 1px solid #e3dede;
        margin: 0;
        transition: padding-bottom .3s ease-out;

        .listing-faq__question {
            align-items: center;
            cursor: pointer;
            display: flex;
            flex-shrink: 0;
            padding: 10px 0;
            transition: padding-bottom .3s ease-out;

            .arrow {
                color: $main-color-dark;
                margin-left: 4px;
                transform: scaleY(1);
                transform-origin: left;
                transition: transform .3s ease;
                width: 24px;
                font-size: 2.5rem;

                &.rotated {
                    transform: scaleY(-1) rotate(45deg); /* Rotate and adjust as needed */
                }
            }

            h3 {
                color: $main-color;
                display: inline;
                font-family: hero-new, sans-serif;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.571rem;
                margin: 0 !important;
            }

        }

        .listing-faq__answer {
            line-height: 2rem;
            margin-left: 0;
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.3s ease, padding 0.3s ease;
            vertical-align: middle;

            a{
                text-decoration: underline;
                cursor: pointer;
                color: $link-blue-color;
            }

            &.arabic-listing-faq__answer {
                margin-inline-start: 0;
                padding-right: unset;
            }

            &.open.arabic-listing-faq__answer {
                max-height: 250px;
                padding-right: unset; 
                margin: 0;
            }

            &.open {
                max-height: 250px;
                padding: 0px 15px 13px 0px;
            }
        }

    }
}