@import "../../styles/variables.scss";

.gallery {
  height: 100%;
  position: relative;

  .gallery-preview {
    position: relative;
    overflow: hidden;
    height: calc(100% - 15.7rem);

    &.with-lightbox {
      .picture.selected {
        cursor: pointer;
      }
    }

    &.cover-picture {
      > .picture {
        background-size: cover;
      }
    }

    &.full-height {
      height: 100%;
    }

    > .picture {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      background-color: #eeecea;
      top: 0;
      bottom: 0;
      // position: absolute;
      transition: left ease-in-out 300ms;
      z-index: 0;

      &.selected {
        left: 0;
        right: 0;
        z-index: 1;
      }
    }

    .buttons {
      width: 2rem;
      position: absolute;
      top: 0;
      bottom: 0;
      background-color: rgba(255, 255, 255, 0.5);
      z-index: 2;

      &.previous {
        left: 0;
      }

      &.next {
        right: 0;
      }
    }
  }

  .arrow {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 10%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-items: center;
    cursor: pointer;

    &.with-thumbnails {
      height: calc(100% - 15.7rem);
    }

    &.transparent-arrows {
      background-color: transparent;
    }

    .ico {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      mask-position: center;
      max-width: 50px;
    }

    &.left-arrow-container {
      left: 0;
    }
    &.right-arrow-container {
      right: 0;
    }
  }

  .dots {
    position: absolute;
    bottom: 2rem;
    height: 1.2rem;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);

    .dot {
      border-radius: 50%;
      width: 1.2rem;
      height: 1.2rem;
      border: 1px solid $secondary-text-color;
      background-color: white;
      display: inline-block;
      margin: 0 0.5rem;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.selected {
        background-color: $main-color;
      }
    }
  }

  .thumbnails {
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    margin-top: 2rem;
    width: 100%;
    scroll-behavior: smooth;

    .thumbnails-container {
      display: flex;
      margin-bottom: 2rem;
    }

    .picture {
      margin: 0 1rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 20rem;
      height: 10rem;
      display: inline-block;
      cursor: pointer;
      border: 1px solid white;

      &.selected {
        border-color: $main-color;
      }

      &:first-child {
        margin-left: 2rem;
      }

      &:last-child {
        margin-right: 2rem;
      }
    }
  }
}
// Overwriting library styles
.ril__caption {
  justify-content: center !important;
}

.ril__captionContent {
  font-size: 1.5rem !important;
}
