@import "../../styles/variables.scss";

.container {
  padding: 1.5rem 6rem;

  @media (max-width: 1400px) {
    padding: 1.5rem 6rem;
  }

  @media (max-width: $mobile-screen-max) {
    padding: 1.5rem 2rem;
  }
}

a {
  color: #000000;
  text-decoration: none;
}

.topbar-container {
  position: fixed;
  background-color: white;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  padding: 1.5rem 6rem;

  @media (max-width: 993px) {
      padding: 1.5rem 2rem;
    }
}

.topbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap-reverse;
    align-items: center;
    width: 100%;
    
    @media (max-width: 993px) {
      margin: 0;
    }

  .fp-logo {
    max-width: 40rem;
  }

  @media (max-width: $mobile-screen-max) {
    .fp-menu {
      display: none;
    }

    .fp-logo {
      width: 30rem;
    }

    .fp-menu-col {
      width: 100%;
      margin-bottom: 2rem;
    }
  }

  .fp-menu {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid $main-color;
    margin-top: 1rem;
    position: relative;

    @media (max-width: $mobile-screen-max) {
      .fp-menu-col {
        width: 100%;
      }

      &.visible {
        display: block;
        position: absolute;
        background-color: white;
        top: 8rem;
        right: 0;
        width: 100%;
        z-index: 2;
        border-top: none;

        > li {
          display: block;
          padding: 1rem 2rem;
          margin: 0;
          border-bottom: 1px solid $primary-text-color;
        }
      }
    }

    li {
      display: inline-block;
      font-weight: 400;
      text-transform: uppercase;
      font-size: 1.5rem;
      margin: 2rem 5rem;
      color: #000000;
      cursor: pointer;
      font-family: "Lato";

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover,
      a:hover {
        color: $main-color;
      }

      a.highlighted {
        color: $main-color;
        position: relative;

        &::before {
          content: "";
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: $main-color;
          position: absolute;
          top: -2.9rem;
          left: 50%;
          transform: translate(-50%, 0);
          border: 5px solid #ffffff;

          @media (min-width: 1800px) {
            border-width: 7px;
          }
          @media (max-width: $mobile-screen-max) {
            content: none;
          }
        }
      }
    }
  }
}

.main {
  position: relative;
  top: 125px;

  @media (max-width: 993px) {
    top: 110px;
  }
}

input {
  height: 50px;
  text-transform: none;
  font-family: "Lato";
  font-weight: 700;
  padding: 0 12px;
  outline: none;
  border: none;
  font-size: 16px;
  color: $black-color;
  border-radius: 5px;

  @media (max-width: $mobile-screen-max) {
    height: 45px;
    font-size: 14px;
  }

  ::placeholder {
    font-size: 16px;

    @media (max-width: $mobile-screen-max) {
      font-size: 14px;
    }
  }
}

textarea {
  outline: none;
  font-size: 16px;
  font-weight: 700;
  font-family: "Lato";
  color: $black-color;
  border: none;
  padding: 1.5rem 12px;
  text-transform: none;
  border-radius: 5px;

  @media (max-width: $mobile-screen-max) {
    font-size: 14px;
  }
}

button,
.button {
  font-family: "Lato";
  font-weight: 700;
  color: white;
  background-color: $main-color;
  outline: none;
  text-transform: uppercase;
  border: none;
  font-size: 15px;
  cursor: pointer;
  height: 50px;
  letter-spacing: 0.09rem;
  border-radius: 5px;

  @media (max-width: $mobile-screen-max) {
    height: 45px;
    font-size: 14px;
  }

  @media (max-width: $mobile-screen-max-lower-resolution) {
    font-size: 12px;
  }
}
.dropdown-container {
  position: relative;
  border-radius: 5px;

  .dropdown {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    background-color: white;
    list-style: none;
    margin: 0;
    padding: 0;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    z-index: 101;

    &.show {
      display: block;
    }

    li {
      text-transform: capitalize;
      padding: 15.5px 3rem 15.5px 18px;
      cursor: pointer;
      font-size: 16px;
      font-family: "Lato";
      font-weight: 700;
      user-select: none;

      &:hover {
        background-color: $main-color;
        color: white;
      }

      @media (max-width: $mobile-screen-max) {
        padding: 13px 3rem 13px 18px;
        font-size: 14px;
      }
    }
  }

  .dropdown-triangle {
    position: absolute;
    top: 0;
    right: 0;
    padding: 25px;
    cursor: pointer;
    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      top: 22px;
      right: 2.2rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.8rem 0.7rem 0 0.7rem;
      border-color: $main-color transparent transparent transparent;
      transition: ease-in-out transform 200ms;

      @media (max-width: $mobile-screen-max) {
        top: 21px;
      }

      &.rotate {
        transform: translate(180deg);
      }
    }
  }

  .dropdown-input {
    width: 100%;
  }
}

.loader {
  color: $main-color;
  font-size: 2rem;
  margin: 10rem auto;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  -webkit-animation: load4 1.3s infinite linear;
  animation: load4 1.3s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
        0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
        0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em,
        2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
        -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0,
        0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em,
        0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em,
        0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em,
        2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em,
        -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em,
        0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }

  &.loader-overlay {
    position: absolute;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);

    &::before {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
}

.hamburger {
  position: relative;
  height: 1.4rem;
  width: 2rem;
  cursor: pointer;

  &.opened {
    div {
      display: none;
    }

    &::after {
      transform: rotate(45deg);
      top: 0.7rem;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &::before {
      transform: rotate(-45deg);
      top: 0.7rem;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  @media (min-width: $mobile-screen-min) {
    display: none;
  }

  &::before,
  &::after {
    content: "";
    height: 0.2rem;
    background-color: black;
    position: absolute;
    left: 0;
    right: 0;
  }

  &::after {
    top: 0;
  }

  &::before {
    bottom: 0;
  }

  div {
    height: 0.2rem;
    background-color: black;
    position: absolute;
    top: calc(50% - 0.1rem);
    left: 0;
    right: 0;
  }
}

.subpage-container {
  min-height: calc(100vh - 27.4rem);

  .header {
    font-weight: normal;
  }
}

.plus-minus-container {
  display: flex;
  background-color: white;
  align-items: center;
  border-radius: 5px;

  .plus-minus-label {
    padding: 0.8rem 0 0.8rem 1rem ;
    text-transform: capitalize;
    font-family: "Lato";
    font-weight: 700;
    color: $black-color;
    font-size: 16px;

    @media (max-width: $mobile-screen-max) {
      font-size: 14px;
    }
  }

  .plus-minus-value {
    position: relative;
    flex: 1;

    &::before,
    &::after {
      content: "";
      position: absolute;
      width: 0.1rem;
      background-color: $main-color;
      top: 1rem;
      bottom: 1rem;
    }

    &::before {
      right: 5rem;
    }

    &::after {
      left: 3.5rem;
      right: 11.5rem;
      left: unset;
    }

    .plus-minus-input {
      width: 100%;
      height: 100;
      text-align: right;
      padding: 0 7.7rem 0 3rem;
      border-radius: 5px;

      @media (max-width: $mobile-screen-max) {
        font-size: 14px;
      }
    }

    .plus,
    .minus {
      position: absolute;
      color: $main-color;
      font-weight: 700;
      font-size: 1.8rem;
      padding: 0.8rem 1rem;
      cursor: pointer;
      user-select: none;
      top: 50%;
      transform: translate(0, -50%);
      font-family: "Lato";
    }

    .minus {
      left: 0.5rem;
      left: unset;
      right: 12.5rem;
    }

    .plus {
      right: 1rem;
    }
  }
}
