@import "../../styles/variables.scss";

.privacy-policy-container {
  font-size: 1.5rem;
  h1 {
    font-weight: normal;
  }

  .clear-agreements {
    margin: 40px 0;
    font-weight: bold;
    span {
      cursor: pointer;
    }
  }
}
