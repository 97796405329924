@import "../../styles/variables.scss";

.search-box {
  height: 16rem;
  margin: 2rem -6rem 0;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  padding-left: 6rem;
  padding-right: 6rem;
  column-gap: 30px;
  row-gap: 15px;

  @media (min-width: $mobile-screen-min) {
    column-gap: 15px;
  }

  @media (max-width: 1340px) {
    height: 22rem;
    padding-bottom: 65px;
    padding-top: 0px;
    justify-content: space-between;
  }

  @media (max-width: 1400px) {
    margin: 2rem -4rem 0;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  @media (max-width: 1065px) {
    flex-wrap: wrap;
    margin: 6rem -2rem 0;
    padding-left: 4rem;
    padding-right: 4rem;
  }

  > div {
    height: 50px;
    border-radius: 5px;
    border: none !important;

    > div > div > div {
      border: none;
      border-radius: 5px;
    }

    @media (max-width: $mobile-screen-max) {
      height: 45px;
      max-width: calc(50% - 15px) !important;
      width: calc(50% - 15px);
      flex-grow: 1;
    }

    @media (max-width: 640px) {
      width: 100%;
      max-width: 100% !important;
    }
  }

  input {
    text-align: left;

    @media (max-width: 460px) {
      text-align: initial;
    }

    &.searchBox {
      width: 100%;
      text-transform: none;

      ::placeholder {
        white-space: normal;
      }
    }
  }

  @media (max-width: $mobile-screen-max) {
    background-position: right bottom;
    height: auto;

    > * {
      display: block;

      @media (max-width: 993px) {
        min-width: unset;
        max-width: 30%;
      }

      @media (max-width: 993px) {
        max-width: 30%;
      }

      @media (max-width: 715px) {
        max-width: calc(50vw - 64px);
      }

      @media (max-width: 460px) {
        max-width: 100%;
        width: 100% !important;
      }
    }

    .DateRangePickerInput {
      width: 100% !important;
    }
  }

  &::before {
    content: "";
    background-color: #f4f2f1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    background-position: bottom;

    @media (max-width: 1065px) {
      top: -4rem;
      bottom: 3.5rem;
    }
  }

  .multi-select {
    background: white;
    position: relative;

    ::placeholder {
      color: $black-color;
      opacity: 1;
    }

    .close-container {
      padding: 10px 0;
      text-align: center;

      &--button {
        width: 200px;
        max-width: 100%;
      }
    }

    .top-close-container {
      position: absolute;
      right: 10px;
      width: 20px;
      top: 8px;
      background-color: #fff;
      z-index: 2;
      text-align: right;

      &--button {
        color: #ad8c63;
        font-weight: bold;
        font-size: 2.8rem;
        transform: rotate(45deg);
        display: inline-block;
      }
    }

    &.area-selector {
      min-width: 240px;
      max-width: 270px;

      #multiselectContainerReact > div {
        border: none;

        input {
          margin: -5px 0 -5px -7px;
        }

        ul {
          border: 1px solid #ccc;

          li {
            padding-left: 22px;
            gap: 10px;
          }
        }
      }

      @media (max-width: 1163px) {
        position: absolute;
        right: 205px;
        bottom: 30px;
      }

      @media (max-width: 1065px) {
        position: static;
        max-width: 400px;
        flex-grow: 1;

        > div {
          position: relative;
        }
      }
    }

    &.bedroom-selector {
      font-size: 1.5rem;
      min-width: 190px;
      max-width: 500px;
      flex-grow: 1;
    }

    .chip {
      display: none;
      background-color: $main-color;
      border-radius: 0;
    }

    .searchBox {
      padding: 0 28px 0 18px;
      margin: 0;

      .area-selector input {
        margin: -6px;
      }
    }

    > div {
      > div {
        > div {
          min-height: 50px;
          border-radius: 0;

          &:first-child {
            padding: 0;
          }
        }
      }
    }

    .optionListContainer {
      border: 1px solid #ccc;
      margin-top: 0 !important;

      .optionContainer {
        border-radius: 0;
        border: none;

        .option {
          display: flex;
          align-items: center;
          padding: 15px 15px 15px 12px;
          font-family: "Lato";
          font-weight: 700;
          font-size: 16px;
          height: 50px;

          &:hover {
            background-color: $main-color;
          }

          @media (max-width: $mobile-screen-max) {
            height: 45px;
            font-size: 14px;
          }
        }

        .checkbox {
          height: auto;
        }

        .highlight {
          background-color: rgba($main-color, 0.5);
        }
      }
    }

    .dropdown-container {
      border-radius: 0;
      margin-left: 3.5rem;
      text-transform: uppercase;
      font-size: 1.5rem;
    }
  }

  .dropdown-triangle {
    position: absolute;
    top: 0;
    right: 0;
    // padding: 25px;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      top: 22px;
      right: 2.2rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.8rem 0.7rem 0 0.7rem;
      border-color: $main-color transparent transparent transparent;
      transition: ease-in-out transform 200ms;

      @media (max-width: $mobile-screen-max) {
        top: 21px;
      }
    }
  }

  .dropdown-triangle-multiselect {
    position: absolute;
    top: 0;
    right: -8px;
    // padding: 25px;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      display: inline-block;
      top: 22px;
      right: 2.2rem;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0.8rem 0.7rem 0 0.7rem;
      border-color: $main-color transparent transparent transparent;
      transition: ease-in-out transform 200ms;

      @media (max-width: $mobile-screen-max) {
        top: 21px;
      }
    }
  }

  .dropdown-container {
    border: 1px solid #cccccc;
    min-width: 150px;
    max-width: 500px;

    > * {
      flex-grow: 1;
    }

    .dropdown-input {
      border-radius: 5px;
      padding: 0 28px 0 18px;
      cursor: pointer;
    }
  }

  .DateRangePickerInput {
    border-radius: 5px;
    min-width: 250px;
    max-width: 350px;
    height: 4rem;

    ::placeholder {
      color: $main-color;
      opacity: 1;
      text-transform: none;
    }

    @media (max-width: $mobile-screen-max) {
      width: 100%;
      max-width: 100%;
    }
  }

  input::placeholder {
    letter-spacing: 1px;
    line-height: 150%;
    color: $black-color;
  }

  .DateRangePicker {
    border: 1px solid #cccccc;
    background-color: #fff;

    .DateRangePicker_picker__withCloseButton {
      &.DateRangePicker_picker {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 0, 0, 0.07);

        .DayPicker__withBorder {
          box-shadow: none;
        }

        .DateRangePicker_closeButton {
          padding: 12px;

          .DateRangePicker_closeButton_svg {
            fill: $main-color;
          }
        }
      }

      .DayPicker_transitionContainer {
        margin-top: 20px;
      }
    }

    .custom-fang-calendar {
      display: block;
      position: absolute;
      left: 15px;
      top: 36px;
      width: 15px;
      height: 15px;
      background: white;
      transform: rotate(45deg);
      border-left: 2px solid $main-color;
      border-top: 2px solid $main-color;
      z-index: 4;
    }

    .DateRangePicker_picker {
      @media (max-width: $mobile-screen-min) {
        border-top: 2px solid $main-color;
      }
    }

    .DateInput_fang {
      display: none;
    }

    @media (min-width: $mobile-screen-min) {
      .DateInput_fang {
        display: block;
      }

      .custom-fang-calendar {
        display: none;
      }
    }

    .bottom-button-container {
      text-align: center;
      position: absolute;
      bottom: 7px;
      left: 0;
      right: 0;

      &--button {
        width: 70%;
        height: 32px;
        font-size: 14px;
      }

      &--two-elements {
        bottom: 44px;

        button {
          background-color: transparent;
          color: $main-color;
          border: 1px solid $main-color;
        }
      }
    }

    .DateInput_input {
      text-align: left;
      border-radius: 5px;
      color: $black-color;

      @media (max-width: 460px) {
        text-align: left;
      }
    }
  }

  .plus-minus-container {
    max-width: 300px;
    min-width: 210px;
    border: 1px solid #cccccc;

    .plus-minus-label {
      text-transform: capitalize;
      padding: 0.8rem 0 0.8rem 18px;
      letter-spacing: 1px;
    }
  }

  .search-button {
    height: 50px;
    width: 23rem;
    min-width: 160px;

    @media (max-width: 1340px) {
      position: absolute;
      bottom: 30px;
      right: 45px;
      width: 19rem;
    }

    @media (max-width: 1400px) {
      right: 30px;
    }

    @media (max-width: 1065px) {
      position: static;
      max-width: 200px;
      flex-grow: 1;
    }

    @media (max-width: $mobile-screen-max) {
      position: initial;
      height: 45px;
      max-width: calc(50% - 15px);
    }

    @media (max-width: 640px) {
      margin: 0 auto;
      max-width: 100%;
    }
  }
}

.search-result-container {
  display: flex;

  .dropdown-input {
    text-align: left;
    text-transform: capitalize;
  }
}

.properties-list-col {
  flex: 0 0 70%;
  min-width: 85rem;

  @media (max-width: 1300px) {
    flex: 0 0 100%;
    min-width: auto;
  }
}

.free-text-container {
  margin: 0 1.5rem;

  .free-text-input {
    width: 100%;
  }
}

.properties-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  overflow: auto;
  height: calc(100vh - 33.5rem);
  gap: 3rem;
  padding-right: 1rem;

  @media (max-width: $mobile-screen-max) {
    height: auto;
    grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  }
}

.description-list {
  display: flex;
  .rental-guide {
    min-width: 85rem;
    flex: 0 0 70%;

    @media (max-width: 1300px) {
      flex: 0 0 100%;
      min-width: auto;
    }
  }
}

.property-item {
  background-color: $card-background;
  width: '100%';
  border: 0.1rem solid transparent;
  align-self: flex-start;
  // min-height: 45rem;

  @media (min-width: 644px) {
    &.last-in-row {
      .DateRangePicker_picker {
        left: unset !important;
        right: 0 !important;
      }
    }
  }
  
  .property-description {
    height: 9.6rem;
    align-items: flex-start;

    @media (min-width: 750px) and (max-width: 890px) {
      height: 10.8rem;
    }
  }

  &:hover {
    border: 0.1rem solid $main-color;
  }

  @media (max-width: 750px) {
    width: 100%;
    min-height: auto;
    margin-left: 0 !important;
    margin-right: 0 !important;

    .property-description {
      height: auto;
    }
  }

  @media (min-width: 751px) {
    &:nth-child(-n + 3) {
      margin-top: 0;
    }

    &:nth-last-child(-n + 3) {
      margin-bottom: 0;
    }
  }

  .gallery .gallery-preview {
    height: 29.2rem !important;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .property-additional-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;

    @media (max-width: $mobile-screen-max) {
      padding-top: 2rem;
    }

    .details-button {
      padding: 1.3rem 3.5rem;
      font-size: 13px;
      height: unset;

      @media (max-width: $mobile-screen-max-lower-resolution) {
        font-size: 12px;
      }
    }

    .expander {
      text-transform: uppercase;
      user-select: none;
      color: $main-color;
      font-size: 1.4rem;
      letter-spacing: 1px;
      font-weight: 500;
      cursor: pointer;
      display: flex;
      letter-spacing: 0.09rem;
    }

    .expand-text {
      display: inline-block;
      line-height: 2.7rem;
      font-family: "Lato";
    }

    .circle-expander {
      width: 2.5rem;
      height: 2.5rem;
      border: 0.2rem solid $main-color;
      border-radius: 50%;
      display: inline-block;
      margin-left: 1.5rem;
      position: relative;

      &::after {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0.4rem 0 0.4rem 0.8rem;
        border-color: transparent transparent transparent $main-color;
        position: absolute;
        top: calc(50% - 0.4rem);
        left: calc(50% - 0.3rem);
        transition: transform ease-in-out 200ms;
      }

      &.expanded {
        &::after {
          transform: rotate(90deg);
          left: calc(50% - 0.4rem);
          top: calc(50% - 0.3rem);
        }
      }
    }
  }

  .inline-booking {
    max-height: 0;
    overflow: hidden;
    transition: max-height ease-in-out 0.2s;
    padding: 0 1rem;

    &.expanded {
      max-height: 50rem;

      &.o-visible {
        overflow: visible;
      }
    }
  }
}

.search-map-col {
  width: 100%;
  padding-left: 4rem;

  @media (max-width: 1400px) {
    padding-left: 2rem;
  }

  @media (max-width: 1300px) {
    display: none;
  }

  .search-map {
    position: relative;
    height: calc(100vh - 29.2rem);
    margin-top: 3rem;
  }

    .gm-style-iw-d {
      padding-right: 12px;
      padding-left: unset;
      padding-bottom: 12px;
      overflow: unset !important;
    }

    .gm-style-iw-ch{
      padding-top: unset !important;
    }
  
    .gm-ui-hover-effect {
      width: 21px !important;
      height: 14px !important;

      > span {
        width: 17px !important;
        height: 15px !important;
        margin: 0 !important;
      }
    }
}

  .arabic-map-col {

    .gm-style-iw-d{
      padding-right: unset;
      padding-left: 12px;
    }
    
    padding-left: unset !important;
    padding-right: 4rem;

    @media (max-width: 1400px) {
      padding-right: 2rem;
    }
  }

.marker-tooltip {
  max-width: 380px;
  min-width: 184px;

  .marker-error {
    font-size: 1.7rem;
    font-family: "Futura LT Pro", "Futura LT ProRU", -apple-system,
      BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
      "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;

    &.found-clear {
      color: $main-color;
    }
  }

  &.found-clear {
    .property-item::before {
      content: "";
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 2;
    }
  }

  .property-item {
    position: relative;
    width: 100%;
    // min-height: 29rem;
    border: 0.1rem solid transparent !important;
    margin-right: unset;

    .property-description {
      height: auto;
      padding: 1rem 1rem 1rem;

      .per-night {
        font-size: 15px;
      }
    }

    .gallery-preview {
      height: 20rem !important;
    }

    .property-name {
      font-size: 1.7rem;
      cursor: pointer;
    }

    .property-price {
      font-size: 2rem;
    }
  }
}

.results-header {
  height: fit-content;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  align-items: center;
  justify-content: flex-end;

  .dropdown-container {
    border: 1px solid $main-color;

    .dropdown-input {
      cursor: pointer;
    }
    .dropdown-height {
      height: 30px !important;
      width: 155px;
    }
    .dropdown-triangle-height {
      position: absolute;
      top: -8px;
      right: -8px;
    }
  }

  .properties-sorting {
    input {
      padding: 0 28px 0 18px;
    }

    @media (max-width: 640px) {
      flex: 0 0 100%;
    }
  }
}

.total-properties {
  font-weight: lighter;
  font-size: 2.2rem;
  text-align: right;
  color: $secondary-text-color;
  letter-spacing: 0.09rem;
  width: 100%;
  padding: 1rem 1rem;
  grid-column: 1 / -1;
}

.search-by-name {
  font-weight: lighter;
  font-size: 1.5rem;
  text-align: right;
  color: $secondary-text-color;
  letter-spacing: 0.09rem;
  width: 100%;
  padding: 0 1rem 1rem 1rem;

  .link {
    cursor: pointer;
  }
}

.pagination {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  font-size: 1.4rem;
  margin: 2rem 0;
  grid-column: 1 / -1;

  @media (max-width: 1300px) {
    width: 100%;  
  }

  li {
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid black;
    margin: 0.5rem;

    &.selected {
      border-color: $main-color;
      color: $main-color;
    }
  }
}

.search-by-name-popup {
  z-index: 10;
  position: fixed;
  top: 30%;
  background: $card-background;
  padding: 2rem;
  max-width: 40rem;
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;

  &.hidden {
    display: none;
  }

  .search-by-name-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .close-modal {
    padding: 0.5rem;
    cursor: pointer;
    font-size: 2.7rem;
    position: absolute;
    right: 1rem;
    top: 0;
    color: $main-color;
  }

  .sent-email {
    font-size: 2rem;
    text-align: center;
  }

  .header {
    color: black;
    font-size: 2rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .input-container {
    width: 100%;
    margin-top: 1rem;

    .free-text-input {
      width: 100%;
    }
  }

  .button {
    width: 100%;
    margin-top: 2rem;
  }
}

//// OVERWRITNG CSS FOR ARABIC VERSION
.arabic-search-box {
  direction: rtl !important;

   input {
    text-align: right;

    @media (max-width: 460px) {
      text-align: right;
    }
  }

  .dropdown-triangle-multiselect {
    right: unset;
    left: 0;
  }

  .multi-select {
    .searchBox{
      padding: 0 10px 0 18px !important;
    }

    .top-close-container{
      cursor: pointer;
      position: absolute;
      background-color: #fff;
      z-index: 2;
      text-align: center;
      right: unset;
      top: 10px;
      left: 0;
      padding: 0px 25px 0px;
    }

    .optionListContainer {
      .optionContainer {
          .option {
            padding: unset;
            padding-right: 10px;
          }
        .checkbox {
          margin-right: unset;
          margin-left: 1rem;
        }
      }
    } 
  }

  .multi-select.area-selector {
     #multiselectContainerReact > div {
      padding-right: 0;

      ul li {
        padding-left: unset;
        grid-gap: 15px;
        gap: 15px;
        text-align: right;
      } 

      input {
        margin: -5px 0 -5px 0px;
      }
    } 
  }

  .dropdown-triangle {
      right: unset !important;
      left: 7px;
      padding: 25px;
      cursor: pointer;

      &::after {
        right: unset !important;
      }
  }

  .dropdown-input {
    width: 80%;
  }

  .plus-minus-container {

    .plus-minus-label {
      padding: 0.8rem 1.5rem 0.8rem 0;
    }
  
    .plus-minus-value {
      &::before {
        left: 5rem;
        right: unset;
      }
  
      &::after {
        right: unset;
        left: 11.5rem;
      }
  
      .plus-minus-input {
        text-align: left;
        padding: 0 3rem 0 7.7rem;
      }
  
      .minus {
        left: 12.5rem;
        right: unset;
      }
  
      .plus {
        right: unset;
        left: 1rem;
      }
    }
  }


   .DateRangePicker {
    direction: ltr !important;

    .DateInput_input {
      text-align: right;
    }

    .custom-fang-calendar {
      left: unset;
      right: 15px;
    }
    
    // Daterangepicker Component Classes
    .DateRangePicker_picker {
      left: unset !important;

      @media (max-width: 991px) {
        right: 0px !important;
      }
    }

    .DateInput_fang {
      left: unset;
      right: 22px;
    }
  }
}

.arabic-search-result-container {
  direction: rtl !important;
  .dropdown-input {
    text-align: right;
  }

  .dropdown-container {
    .dropdown-triangle {
      right: unset;
      left: 0;
    }
  } 
}

.arabic-map-tooltip{
  .property-description {
    gap: 0.3rem;
    .property-name {
      padding-right: unset;
      direction: ltr;
      text-align: right;
    }
    .property-currency{
      margin-left: unset;
      margin-right: 0.3rem;
    }
    .sum-switch {
      text-align: left;
  
      .per-night {
        margin-left: unset;
      }
    }
  }
}

.arabic-properties-list {

  padding-left: 1rem;
  padding-right: unset;
  .property-item {
      .DateRangePicker_picker {
        direction: ltr !important;
      }
  
      &.last-in-row{
        .DateRangePicker_picker {
          left: unset !important;
          right: 0 !important;
        }
      }

      .property-additional-info {
        .circle-expander {
          margin-left: unset;
          margin-right: 1.5rem;

          &::after {
            transform: rotate(180deg);
            top: calc(50% - 0.4rem);
            left: unset;
            right: calc(50% - 0.3rem);
          }
  
          &.expanded {
            &::after {
              transform: rotate(90deg);
              left: calc(50% - 0.4rem);
              top: calc(50% - 0.3rem);
              right: unset;
            }
          }
  
        }
      }

      .property-description {
        .property-name {
          padding-right: unset;
          direction: ltr;
          text-align: right;
        }
  
        .sum-switch {
          text-align: left;
  
          .per-night {
            margin-left: unset;
          }
        }
      }

      .booking-details {
        .DateRangePickerInput {
          padding-right: unset;
        }
      } 

      .DateInput_fang {
        left: unset;
      }

      .plus-minus-container {
        .plus-minus-label {
          padding: 0.8rem 1rem 0.8rem 0rem;
        }

       .plus-minus-value {
        .plus-minus-input {
          padding: 0 3rem 0 7.7rem;
          text-align: left;
        }

        &::before {
          right: unset;
          left: 5rem;
        }

        &::after {
          right: unset;
          left: 11.5rem;
        }

        .minus {
          left: 12.5rem;
          right: unset;
        }

        .plus {
          left: 1rem;
          right: unset;
        }
       }
      }
    }
}

.arabic-modal {
  direction: rtl;
  
  .swal-text {
    text-align: right;
    padding: 0 15px;
  }
}

.gallery {
  direction: ltr !important;
}