@import "../../styles/variables.scss";

.breadcrumbs {
  font-size: 1.8rem;
  font-family: "Lato";
  font-weight: normal;
  margin: 2rem 0;

  .active {
    color: $main-color-dark;
  }
}