@import "../../styles/variables.scss";

.about-container {
  text-align: center;

  .header {
    text-transform: uppercase;
    font-weight: normal;
  }

  .description {
    line-height: 100%;
  }

  .search-map {
    width: 100%;
    height: 40rem;
    position: relative;
    margin: 4rem 0 3rem;
  }

  .new-properties {
    margin: 8rem 0 2rem;
  }

  .properties {
    overflow: auto;
    white-space: nowrap;

    .property-item {
      display: inline-block;
      width: 62rem;
      margin: 2rem !important;
      max-width: calc(100vw - 4rem);

      &:first-child {
        margin-left: 0 !important;
      }
      
      &:last-child {
        margin-right: 0 !important;
      }

      .property-info {
        overflow: hidden;
      }

      .property-name {
        white-space: pre-wrap;
        text-align: left;
      }

      .property-pricing {
        padding-left: 1rem;
      }
    }

    .property-details {
      text-align: left;
    }
  }
}
